import Font, { Text } from "react-font";
import { useTranslation } from "react-i18next";
import { useSectionInView } from "./../utils/hooks";
import React, { useState, useEffect } from 'react';

function About() {
  const { t } = useTranslation();
  const { ref } = useSectionInView(`${t("About")}`);
  const profilePic = require('../img/mathias-notaert.jpg');

  const [deviceName, setDeviceName] = useState('');

  useEffect(() => {
    const getDeviceName = () => {
      const userAgent = navigator.userAgent;
      let name = 'Apparaat';

      if (/iPhone/.test(userAgent)) {
        name = 'iPhone';
      } else if (/iPad/.test(userAgent)) {
        name = 'iPad';
      } else if (/iPod/.test(userAgent)) {
          name = 'iPod';
      } else if (/Android/.test(userAgent)) {
        name = 'Android';
      } else if (/Windows Phone/.test(userAgent)) {
        name = 'Windows phone';
      } else if (/Windows/.test(userAgent)) {
        name = 'Windows PC';
      } else if (/Macintosh/.test(userAgent)) {
        name = 'Mac';
      } else if (/Linux/.test(userAgent)) {
        name = 'Linux PC';
      }
      setDeviceName(name);
    };
    getDeviceName();
  }, []);

  return (
    <section ref={ref} id={t('about')} className="section section--about">
      <div className="container">
        <Font family='Montserrat'>
            <h5 className="text-center text-uppercase">{t('About subtitle')}</h5>
        </Font>
        <Font family='Montserrat' weight={700}>
          <h3 className="text-center text-uppercase">
            {t('About')}
          </h3>
        </Font>
        <div className="row">
          <div className="col-12 col-md-9 order-2 order-md-1">
            <Font family='Montserrat'>
              <p className="mb-0"><span className="text-accent">{t("Name")}: </span>{t("MN_NAME")} </p>
              <p><span className="text-accent">{t("Function")}: </span>{t("Front-End Developer")} </p>
            </Font>
            <Font family='Montserrat'>
            <p>{t("aboutParagraph1")} {deviceName}.</p>
            </Font>
            <Text family='Montserrat'>
            {t("aboutParagraph2")}
            </Text>
            <Text family='Montserrat'>
            {t("aboutParagraph3")}
            </Text>
          </div>
          <div className="col-12 col-md-3 order-1 order-md-2">
            <div className="image--bordered">
              <img src={profilePic} alt="Mathias Notaert" className="img-fluid"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
