export const timeline = {
    works: [
        {
            date: '2019 - ...',
            title: 'Front-End Developer',
            location: 'Colruyt Group, Halle',
            description: 'Work description 1',
            image: 'colruytgroup.jpg'
        },
        {
            date: '2017 - 2019',
            title: 'Webmaster',
            location: 'Colruyt Group, Halle',
            description: 'Work description 2',
            image: 'colruytgroup.jpg'
        },
    ],
    educations: [
        {
            date: '2014 - 2017',
            title: 'Graphic and Digital Media - Multimedia Production',
            location: 'Artevelde, Ghent',
            description: 'Accomplished.',
            image: 'artevelde.png'
        },
        {
            date: '2008 - 2014',
            title: 'Economics Modern Languages',
            location: 'Sint-Godelieve Institute',
            description: 'Accomplished.',
            image: 'sgi.png'
        },
    ],
    
}
