export const navTabs = [
    {
        label: 'Home',
        link: 'home'
    },
    {
        label: 'About',
        link: 'about'
    },
    {
        label: 'Experience',
        link: 'experience'
    },
    {
        label: 'Skills',
        link: 'skills'
    },
    // {
    //     label: 'Works',
    //     link: 'works'
    // },
    {
        label: 'Contact',
        link: 'contact'
    },
]

export const pages = [
    {
        label: 'Privacy Policy',
        link: 'privacy-policy'
    }
]