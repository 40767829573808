import Font from "react-font";
import { useTranslation } from "react-i18next";
import { useSectionInView } from "./../utils/hooks";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { skills } from './../data/skill-items';

function Skills() {
  const { t } = useTranslation();
  const { ref } = useSectionInView(`${t("Skills")}`);

  return (
    <section ref={ref} id={t('skills')} className="section">
      <div className="container">
        <Font family='Montserrat'>
            <h5 className="text-center text-uppercase">{t('Skills subtitle')}</h5>
        </Font>
        <Font family='Montserrat'>
          <h3 className="text-center text-uppercase">
            {t('Skills')}
          </h3>
        </Font>
        <div className="row">
          <Font family='Montserrat'>
          <h4 className="header--skill">{t('Languages')}</h4>
          </Font>
        </div>
        <div className="row mb-5">
          {skills.languages.map((skill: any) => (
            <div className="col-3 col-md-2 col-lg-1 mb-4">
              <CircularProgressbar value={skill.percentage}
                  text={skill.name}
                  styles={buildStyles({ pathColor: '#86C232', trailColor: '#191B1D', textColor: '#F2F4F3', textSize: '20px'})} />
            </div>
          )
        )}
        </div>
        <div className="row">
          <Font family='Montserrat'>
          <h4 className="header--skill">{t('Technical')}</h4>
          </Font>
        </div>
        <div className="row mb-5">
          {skills.technical.map((skill: any) => (
            <div className="col-3 col-md-2 col-lg-1 mb-4">
              <CircularProgressbar value={skill.percentage}
                  text={skill.name}
                  styles={buildStyles({ pathColor: '#86C232', trailColor: '#191B1D', textColor: '#F2F4F3', textSize: '13px'})} />
            </div>
          )
        )}
        </div>
        <div className="row">
          <Font family='Montserrat'>
          <h4 className="header--skill">{t('Software')}</h4>
          </Font>
        </div>
        <div className="row">
          {skills.software.map((skill: any) => (
            <div className="col-3 col-md-2 col-lg-1 mb-4">
              <CircularProgressbar value={skill.percentage}
                  text={skill.name}
                  styles={buildStyles({ pathColor: '#86C232', trailColor: '#191B1D', textColor: '#F2F4F3', textSize: '13px'})} />
            </div>
          )
        )}
        </div>
      </div>
    </section>
  );
}

export default Skills;