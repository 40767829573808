import Font from "react-font";
import { useTranslation } from "react-i18next";
import { useSectionInView } from "./../utils/hooks";
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { HiOutlineMail } from "react-icons/hi";
import { TbPhone } from "react-icons/tb";
import { IoLocationOutline, IoPerson } from "react-icons/io5";

function Contact() {
  const { t } = useTranslation();
  const { ref } = useSectionInView(`${t("Contact")}`);
  const iconStyle = { color: '#F2F4F3', fontSize: "1.25rem", marginRight: "10px", marginBottom: "7px", marginTop: "5px" }
  const [resultMessage, setResultMessage]: any = useState('');
  const [errorMessage, setErrorMessage]: any = useState('');

  const SERVICE_ID = 'service_3xk2fcf';
  const TEMPLATE_ID = 'template_94u0ivq';
  const PUBLIC_KEY = '8fr_SEVPA5GFKdax9';

  const form = useRef() as any;

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
      .then((result: any) => {
          const message = t("SUBMIT_SUCCESSFUL");
          setResultMessage(message);
      }, (error: any) => {
          const errormsg = t("SUBMIT_ERROR");
          setErrorMessage(errormsg);
      });
  };

  return (
    <section ref={ref} id={t('contact')} className="section">
      <div className="container">
        <Font family='Montserrat'>
            <h5 className="text-center text-uppercase">{t('Contact subtitle')}</h5>
        </Font>
        <Font family='Montserrat'>
          <h3 className="text-center text-uppercase">
          {t('Contact')}
          </h3>
        </Font>
          <div className="row">
          <p className="col-12 d-block d-sm-none mb-5">{t('Do you have a question or do you want to collaborate?')}<br />{t('Please fill out the form.')}</p>
          <div className="col-12 col-sm-6 mb-5 mb-sm-0">
            <Font family='Montserrat'>
              <form className={`contact ${resultMessage || errorMessage ? 'contact--inactive' : ''}`} ref={form} onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-6">
                    <label>{t('Name')}</label>
                    <input type="text" name="user_name" />
                  </div>
                  <div className="col-6">
                    <label>{t('Email')}</label>
                    <input type="email" name="user_email" />
                  </div>
                </div>
                <label>{t('Message')}</label>
                <textarea name="message" />
                {(!resultMessage && !errorMessage) &&
                  <button type="submit">{t('Send')}</button>
                }
              </form>
              {resultMessage &&
                <div className="contact__notification contact__notification--success">
                  <p>{t(resultMessage)}</p>
                </div>
              }
              {errorMessage &&
                <div className="contact__notification contact__notification--warning">
                  <p>{t(errorMessage)} <span onClick={() => { 
                      window.location.href="#" + t('contact');
                      window.location.reload();
                    }} 
                    > {t('Try again')}</span></p>
                </div>
              }
            </Font>
          </div>
          <div className="col-12 col-sm-1"></div>
          <div className="col-12 col-sm-5 mb-5 mb-sm-0">
            <Font family='Montserrat'>
              <p className="d-none d-sm-block mb-5">{t('Do you have a question or do you want to collaborate?')}<br />{t('Please fill out the form.')}</p>
              <p>{t('... or contact me via')}</p>
              <p className="text-uppercase mb-0"><IoPerson style={iconStyle} />{t('MN_NAME')}<br />
              <IoLocationOutline style={iconStyle} />{t('MN_ADRES')}<br />
              <TbPhone style={iconStyle} />{t('MN_PHONE')}<br />
              </p>
              <HiOutlineMail style={iconStyle} /><a href={`mailto:${t('MN_EMAIL')}`}>{t('MN_EMAIL')}</a>
            </Font>
          </div>
          </div>
      </div>
    </section>
  );
}

export default Contact;
