import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';

function MetaDescription() {
  const { t } = useTranslation();

  return (
    <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{t('metaTitle')}</title>
      <meta name="description" content={t('metaDescription')} />
      <link rel="canonical" href={t('metaSite')} />
    </Helmet>
    </div>
  );
}

export default MetaDescription;