import { Text } from "react-font";
import { socials } from "./../data/social-items";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const iconStyle = { color: "#F2F4F3", fontSize: "1.25rem" }

  return (
    <footer className='footer'>
      <div className='footer__socials'>
          {socials.map((social: any) => (
            <a className="footer__social"
              href={social.link}
              target="_blank" rel="noreferrer">
                <social.icon style={iconStyle}/>
            </a>
        ))}
      </div>
      <Text className="footer__copyright" family='Montserrat'>
        {t('Copyright')}
      </Text>
    </footer>
  );
}

export default Footer;