import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import globals from './data/globals';

import translationEN from "./i18n/en/translation.json";
import translationNL from "./i18n/nl/translation.json";
import translationFR from "./i18n/fr/translation.json";

const resources = {
  en: {
    translation: translationEN
  },
  nl: {
    translation: translationNL
  },
  fr: {
    translation: translationFR
  }
};

i18n
  .use(Backend)
  .use(new LanguageDetector(null, { lookupLocalStorage: globals.LANGUAGE_COOKIE }))
  .use(initReactI18next)
  .init({
    fallbackLng: globals.LANGUAGE_FALLBACK,
    debug: true,
    resources,
    supportedLngs: globals.LANGUAGES,
    interpolation: {
      escapeValue: false,
    }
  });

  export default i18n;