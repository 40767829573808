const LANGUAGE_COOKIE = 'mnLang';
const LANGUAGES = ['en', 'nl', 'fr'];
const LANGUAGE_FALLBACK = 'nl';

const globals = {
    LANGUAGE_COOKIE,
    LANGUAGES,
    LANGUAGE_FALLBACK,
};

export default globals;