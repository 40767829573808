import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import { useTranslation } from "react-i18next"; 
import { useActiveSectionContext } from '../utils/active-section';
import { navTabs } from '../data/nav-items';

function ScrollToTop() {

  const iconStyle = { color: "#F2F4F3", fontSize: "1.75rem" }
  const { t } = useTranslation();
  const home = t(navTabs[0].link);
  const { activeSection, setActiveSection, setTimeOfLastClick } =
  useActiveSectionContext();

  return (
    <a className={`scroll-to-top ${activeSection === `${t('Home')}` ? 'scroll-to-top--hidden' : ''}`}
       href={'#' + home}
       onClick={() => {
        setActiveSection(`${t('Home')}`);
        setTimeOfLastClick(Date.now());
      }} 
    >
        <MdKeyboardDoubleArrowUp 
          style={iconStyle}
        />
    </a>
  );
}

export default ScrollToTop;