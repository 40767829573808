import Font from 'react-font'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import i18next from 'i18next';
import { navTabs } from '../data/nav-items';
import { useTranslation } from "react-i18next";
import LanguageSwitch from '../components/LanguageSwitch';
import { useActiveSectionContext } from '../utils/active-section';

function Header() {
  const mobileWidth = 992;
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { activeSection, setActiveSection, setTimeOfLastClick } = useActiveSectionContext();
  const logo = require('../img/mn-logo.png');

  const handleNavItemClick = () => {
    const mobileToggle = document.getElementById('header-mobile-toggle');
    const isMobile = window.innerWidth < mobileWidth;
    if (mobileToggle && isMobile) {
      mobileToggle.click();
    }
  };

  const getNavigationLink = (link: string) => {
    const linkToSection = document.getElementById(t(link));
    if(linkToSection) {
      return '#' + t(link);
    } else {
      if(link === 'home') {
        return `/${i18next.language}`;
      }
      return `/${i18next.language}/${t(link)}`;
    }
  }

  return (
    <Navbar expand="lg" className="header">
      <Container className='header__container'>
        <Navbar.Brand href="/" className='header__brand'>
          <img src={logo} alt="mathias-notaert-logo" className="header__logo" />
        </Navbar.Brand>
        <Navbar.Toggle className='header__toggle' id="header-mobile-toggle" aria-controls="basic-navbar-nav">
        </Navbar.Toggle>
        <Navbar.Collapse className='header__collapse' id="basic-navbar-nav">
          <Nav>
            {navTabs.map((nav) => (
              <Font family="Montserrat" weight={300}>
                <Nav.Link key={(nav.label)}
                  href={getNavigationLink(nav.link)}
                  className={`header__link ${activeSection === `${t(nav.label)}` ? 'header__link--active' : ''}`}
                  onClick={() => {
                    setActiveSection(`${t(nav.label)}`);
                    setTimeOfLastClick(Date.now());
                    handleNavItemClick();
                  }}
                >
                  {t(nav.label)}
                </Nav.Link>
              </Font>
            ))}
            <div className="d-block d-lg-none">
              <LanguageSwitch />
            </div>
          </Nav>
        </Navbar.Collapse>
        <div className="d-none d-lg-block">
          <LanguageSwitch />
        </div>
      </Container>
    </Navbar>
  );
}

export default Header;