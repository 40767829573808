export const skills = {
    languages: [
        {
            name: 'NL',
            percentage: 100,
        },
        {
            name: 'ENG',
            percentage: 100,
        },
        {
            name: 'FR',
            percentage: 60,
        },
        {
            name: 'DE',
            percentage: 40,
        },
        {
            name: 'ES',
            percentage: 25,
        },
    ],
    technical: [
        {
            name: 'HTML/CSS',
            percentage: 100,
        },
        {
            name: 'Javascript',
            percentage: 90,
        },
        {
            name: 'TypeScript',
            percentage: 80,
        },
        {
            name: 'VueJS',
            percentage: 80,
        },
        {
            name: 'React',
            percentage: 70,
        },
        {
            name: 'NodeJS',
            percentage: 70,
        },
        {
            name: 'Git',
            percentage: 80,
        },
        {
            name: 'WordPress',
            percentage: 70,
        },
        {
            name: 'AEM',
            percentage: 60,
        },
        {
            name: 'PHP',
            percentage: 60,
        },
        {
            name: 'Java',
            percentage: 40,
        },
    ],
    software: [
        {
            name: 'Adobe CC',
            percentage: 75,
        },
        {
            name: 'Figma',
            percentage: 75,
        },
        {
            name: 'Sketch',
            percentage: 80,
        },
        {
            name: 'Cinema 4D',
            percentage: 60,
        },
    ],
}
