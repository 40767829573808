import { FaFacebookSquare, FaInstagram, FaLinkedin, FaGithub, FaStackOverflow } from "react-icons/fa";

export const socials = [
    {
        social: "Facebook",
        link: "https://www.facebook.com/mathias.notaert",
        icon: FaFacebookSquare
    },
    {
        social: "Instagram",
        link: "https://www.instagram.com/mathiasnotaert/",
        icon: FaInstagram
    },
    {
        social: "LinkedIn",
        link: "https://be.linkedin.com/in/mathias-notaert-70384b131",
        icon: FaLinkedin
    },
    {
        social: "Github",
        link: "https://github.com/mathiasnotaert",
        icon: FaGithub
    },
    {
        social: "StackOverflow",
        link: "https://stackoverflow.com/users/12997804/mathiasnotaert",
        icon: FaStackOverflow
    },
]


