import Font, { Text } from "react-font";
import { useTranslation } from "react-i18next";
import { useSectionInView } from "./../utils/hooks";
import { socials } from '../data/social-items';

function Home() {
  const { t } = useTranslation();
  const { ref } = useSectionInView(`${t("Home")}`);
  const iconStyle = { color: "#F2F4F3", fontSize: "1.5rem" }

  return (
    <section ref={ref} id={t('home')} className="section section--home">
      <div className="container">
        <Font family='Satisfy'>
            <h2 className="header--hello">{t('hello')}</h2>
        </Font>
        <Font family='Montserrat'>
            <h3 className="header--greet"><span className="text-light">{t("I'm")}</span> <strong><span className="text-accent">Mathias</span></strong><span className="text-light">.</span></h3>
        </Font>
        <Text className="header--intro" family="Montserrat">
        {t('introDescription')}
        </Text>
        <div className='header__socials'>
          {socials.map((social: any) => (
            <a className="header__social"
              href={social.link}
              target="_blank" rel="noreferrer">
                <social.icon style={iconStyle}/>
            </a>
        ))}
      </div>
        <Font family='Montserrat'>
        <div className="home-buttons">
          <a href={t("CV_PATH")} download className="btn button button--primary">{t("Download CV")}</a>
          <a href={'#'+t('contact')} className="btn button button--secondary">{t("Contact me")}</a>
        </div>
        </Font>
      </div>
      <a href={'#'+t('about')} className="home-arrow">
          <span></span>
        </a>
    </section>
  );
}

export default Home;