import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation } from "react-i18next";

// Bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';

// Layout
import Header from './layout/Header';
import Footer from './layout/Footer';

// Components
import CookieBanner from './components/CookieBanner';
import ScrollToTop from './components/ScrollToTop';
import MetaDescription from './components/MetaDescription';

// Sections
import Home from './sections/Home';
import About from './sections/About';
import Experience from './sections/Experience';
import Skills from './sections/Skills';
import Contact from './sections/Contact';

// Pages
import PrivacyPolicy from './pages/PrivacyPolicy';
import PageNotFound from './pages/PageNotFound';

// Observer
import ActiveSectionContextProvider from './utils/active-section';

function App() {

  const { t } = useTranslation();
  const language = i18next.language;

  return (
    <Router>
      <ActiveSectionContextProvider>
        <MetaDescription />
        <CookieBanner />
        <Header />

        <Routes>
            {/* Home */}
            <Route
              path={'/'}
              element={
                <>
                  <Home />
                  <About />
                  <Experience />
                  <Skills />
                  <Contact />
                </>
              }
            />

          {/* Home with url */}
          <Route
              path={'/' + language}
              element={
                <>
                  <Home />
                  <About />
                  <Experience />
                  <Skills />
                  <Contact />
                </>
              }
            />

            {/* Sections */}
            <Route path={`/${language}/`+ t('about')} Component={About} />
            <Route path={`/${language}/`+ t('experience')} Component={Experience} />
            <Route path={`/${language}/`+ t('skills')} Component={Skills} />
            <Route path={`/${language}/`+ t('contact')} Component={Contact} />

            {/* Pages */}
            <Route path={`/${language}/`+ t('privacy-policy')} Component={PrivacyPolicy} />
            <Route path={'*'} Component={PageNotFound} />

          </Routes>

        <ScrollToTop />
        <Footer />
      </ActiveSectionContextProvider>
    </Router>
  );
}

export default App;