import Font from 'react-font'
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import Dropdown from 'react-bootstrap/Dropdown';
import { useEffect } from 'react';
import globals from '../data/globals';
import { navTabs, pages } from '../data/nav-items';
import { useLocation, useNavigate } from 'react-router-dom';


function LanguageSwitch() {
  const mobileWidth = 992;
  const languages = globals.LANGUAGES;
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguage = (newLanguage: string) => {
    const lang = localStorage.getItem(globals.LANGUAGE_COOKIE);
    const keywords = [...navTabs.map(tab => tab.link), ...pages.map(page => page.link)];
    const i18nKeywords = keywords.map(keyword => i18n.t(keyword));
    if (lang !== newLanguage) {
      i18n.changeLanguage(newLanguage);
  
      const languageRegex = /\/(en|nl|fr)/;
      const matches = location.pathname.match(languageRegex);
      let newPathname = location.pathname;
      if (matches && matches[1]) {
        newPathname = newPathname.replace(matches[1], newLanguage);
      } else {
        newPathname = `/${newLanguage}`;
      }
  
      i18nKeywords.forEach((keyword, i) => {
        const regex = new RegExp(`/${keyword}`, 'g');
        newPathname = newPathname.replace(regex, `/${i18n.t(keywords[i])}`);
      });
  
      navigate(newPathname);
      window.location.reload();
    }
  };

  const handleNavItemClick = () => {
    const mobileToggle = document.getElementById('header-mobile-toggle');
    const isMobile = window.innerWidth < mobileWidth;
    if (mobileToggle && isMobile) {
        mobileToggle.click();
    }
  };

  useEffect(() => {
    const detectAndChangeLanguage = () => {
      const languageRegex = /\/(en|nl|fr)/;
      const matches = location.pathname.match(languageRegex);
      if (matches && matches[1]) {
        const language = matches[1];
        changeLanguage(language);
      }
    };

    detectAndChangeLanguage();
  }, [location.pathname]);

  return (
    <Font family="Montserrat" weight={500}>
        <Dropdown className="language-switch__toggle">
            <Dropdown.Toggle id="language-switch" className="text-uppercase">
            {(i18next.language)}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {languages.map((language) => (
                    language !== i18next.language && (
                    <Dropdown.Item  className="text-uppercase" key={language} 
                    onClick={() => {
                      changeLanguage(language);
                      handleNavItemClick();
                    }}>{(language)}</Dropdown.Item>
                    )
                ))}
            </Dropdown.Menu>
        </Dropdown>
    </Font>
  );
}

export default LanguageSwitch;