import Font, { Text } from "react-font";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from 'react';

function PageNotFound() {
  const { t } = useTranslation();
  const language = i18next.language;

  return (
<section id={t('page-not-found')} className="section section--page-not-found">
      <div className="container">
        <Font family='Montserrat'>
            <h5 className="text-center text-uppercase">{t('Error 404')}</h5>
        </Font>
        <Font family='Montserrat' weight={700}>
          <h3 className="text-center text-uppercase">
            {t('Page Not Found')}
          </h3>
        </Font>
        <div className="row">
          <div className="col-12 text-center">
            <Font family='Montserrat'>
              <p>{t("Error Page paragraph")} <a href={'/' + language}>{t('Homepage')}</a>.</p>
            </Font>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageNotFound;
