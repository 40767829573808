import Font from "react-font";
import { useTranslation } from "react-i18next";
import { useSectionInView } from "./../utils/hooks";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { MdWorkOutline, MdOutlineSchool, MdOutlineStarOutline } from "react-icons/md";
import { timeline } from '../data/timeline-items';


function Experience() {
  const { t } = useTranslation();
  const { ref } = useSectionInView(`${t("Experience")}`);
  const iconStyle = { color: "#F2F4F3", fontSize: "1.75rem" }

  return (
    <section ref={ref} id={t('experience')} className="section">
      <div className="container">
        <Font family='Montserrat'>
            <h5 className="text-center text-uppercase">{t("Experience subtitle")}</h5>
        </Font>
        <Font family='Montserrat'>
          <h3 className="text-center text-uppercase">
            {t("Experience")}
          </h3>
        </Font>
        <Font family="Montserrat">
          <VerticalTimeline>
          {timeline.works.map((work: any) => (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date={work.date}
              icon={<MdWorkOutline style={iconStyle} />}
            >
              <h4 className="vertical-timeline-element-title">{work.title}</h4>
              <h5 className="vertical-timeline-element-subtitle">{work.location}</h5>
              <p>
              {t(work.description)}
              </p>
              {/* <img className="vertical-timeline-element-image" src={'/img/experience/' + work.image} /> */}
            </VerticalTimelineElement>
          ))}

          {timeline.educations.map((education: any) => (
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              date={education.date}
              icon={<MdOutlineSchool style={iconStyle} />}
            >
              <h4 className="vertical-timeline-element-title">{t(education.title)}</h4>
              <h5 className="vertical-timeline-element-subtitle">{t(education.location)}</h5>
              <p>
                {t(education.description)}
              </p>
              {/* <img className="vertical-timeline-element-image" src={'/img/experience/' + education.image} /> */}
            </VerticalTimelineElement>
          ))}
          <VerticalTimelineElement
              contentStyle={{border: 'none'}}
              icon={<MdOutlineStarOutline style={iconStyle} />} />
          </VerticalTimeline>
        </Font>
      </div>
    </section>
  );
}

export default Experience;