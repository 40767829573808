import CookieConsent from "react-cookie-consent";
import i18next from 'i18next';
import { useTranslation } from "react-i18next"; 

function CookieBanner() {
  const { t } = useTranslation();
  const language = i18next.language;

  return (
      <CookieConsent
        location="bottom"
        buttonText={t('I understand')}
        cookieName="mnCookie"
        expires={150}
      >
        {t('cookie policy')}{" "}<a href={language + '/' + t('privacy-policy')}>{t('Privacy policy')}</a>
      </CookieConsent>
  );
}

export default CookieBanner;