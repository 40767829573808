import Font, { Text } from "react-font";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from 'react';

function PrivacyPolicy() {
  const { t } = useTranslation();
  const language = i18next.language;

  return (
<section id={t('privacy-policy')} className="section section--privacy-policy">
      <div className="container">
        <Font family='Montserrat'>
            <h5 className="text-center text-uppercase">{t('Privacy policy subtitle')}</h5>
        </Font>
        <Font family='Montserrat' weight={700}>
          <h3 className="text-center text-uppercase">
            {t('Privacy policy')}
          </h3>
        </Font>
        <div className="row">
          <div className="col-12">
            <Font family='Montserrat'>
              <p>{t("Privacy Policy paragraph 0")}</p>

              <h5>{t("Privacy Policy title 1")}</h5>
              <p>{t("Privacy Policy paragraph 1")}</p>

              <h5>{t("Privacy Policy title 2")}</h5>
              <p>{t("Privacy Policy paragraph 2")}</p>

              <h5>{t("Privacy Policy title 3")}</h5>
              <p>{t("Privacy Policy paragraph 3")}</p>

              <h5>{t("Privacy Policy title 4")}</h5>
              <p>{t("Privacy Policy paragraph 4")}</p>

              <h5>{t("Privacy Policy title 5")}</h5>
              <p>{t("Privacy Policy paragraph 5")}</p>

              <h5>{t("Privacy Policy title 6")}</h5>
              <p>{t("Privacy Policy paragraph 6")}</p>

              <h5>{t("Privacy Policy title 7")}</h5>
              <p>{t("Privacy Policy paragraph 7")} <a href={'/' + language + '/' + t('contact')}>{t("Contact Form")}</a>.</p>

              <p>{t("Privacy Policy paragraph 8")}</p>
            </Font>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
